import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    evidenceSiteId: ''
};

export const SearchEvidenceSlice = createSlice({
    name: 'SearchEvidence',
    initialState,
    reducers: {
        addEvidenceSiteId(state, action) {
            state.evidenceSiteId = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { addEvidenceSiteId } = SearchEvidenceSlice.actions;

export default SearchEvidenceSlice.reducer;
