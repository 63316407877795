import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationAS from './locales/as.json';
import translationBN from './locales/bn.json';
import translationEN from './locales/en.json';
import translationHI from './locales/hi.json';
import translationGU from './locales/guj.json';
import translationAR from './locales/ar.json';

const resources = {
    gu: { translation: translationGU },
    hi: { translation: translationHI },
    bn: { translation: translationBN },
    as: { translation: translationAS },
    en: { translation: translationEN },
    ar: { translation: translationAR }
};

const lng = localStorage?.getItem('lng') || 'en';

i18n.use(initReactI18next).init({ resources, lng, keySeparator: false, interpolation: { escapeValue: false } });

export default i18n;
