import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project imports
import { MENU_OPEN } from 'store/actions';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTranslation } from 'react-i18next';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item }) => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }
    }, []);

    return (
        <ListItemButton
            sx={{
                borderRadius: `${customization.borderRadius}px`,
                pl: 2,
                ml: 3,
                '&.Mui-selected': {
                    backgroundColor: '#485468 !important'
                },
                '&:hover': {
                    backgroundColor: '#485468 !important'
                },
                py: 1,
                color: 'white'
            }}
            selected={item.id === i18n.language}
            onClick={() => {
                i18n.changeLanguage(item.id);
                localStorage.setItem('lng', item.id);
            }}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: 25, color: '#bababa' }}>
                <FiberManualRecordIcon sx={{ width: 12, height: 12 }} style={{ color: '#FFFFFF' }} />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant="body1" style={{ color: '#FFFFFF' }}>
                        {item.title}
                    </Typography>
                }
            />
        </ListItemButton>
    );
};

export default NavItem;
