import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, IconButton, useMediaQuery } from '@mui/material';

// third-party
import { IconX } from '@tabler/icons-react';
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from './MenuList';
import Logo from 'ui-component/Logo';
import { drawerWidth } from 'store/constant';

/* ============================================================ */
/*                       ⚛️ SIDEBAR DRAWER ⚛️                  */
/* ============================================================ */
const Sidebar = ({ drawerOpen, drawerToggle, window, menuItem }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const drawer = matchUpMd ? (
        <Box sx={{ bgcolor: '#101626', minHeight: '100vh', overflow: 'auto' }}>
            <PerfectScrollbar
                component="div"
                style={{
                    height: 'calc(100vh - 60px)',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    paddingTop: '30px'
                }}
            >
                <MenuList menuItem={menuItem} />
            </PerfectScrollbar>
        </Box>
    ) : (
        <Box sx={{ bgcolor: '#101626', overflow: 'auto' }}>
            <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
                <IconButton onClick={drawerToggle}>
                    <IconX color="white" />
                </IconButton>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center' }}>
                <Logo width="60" />
            </Box>
            <PerfectScrollbar component="div" style={{ height: 'calc(100vh - 56px)', padding: '10px', marginBottom: '10px' }}>
                <MenuList menuItem={menuItem} />
            </PerfectScrollbar>
        </Box>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: { top: '50px' }
                    },
                    position: drawerOpen ? 'fixed' : 'static'
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;
