import { lazy } from 'react';

import Loadable from 'ui-component/Loadable';

// === Layout ===//
import MainLayout from 'layout/MainLayout';
import ViewTimeline from 'layout/ViewUserLayout';

// === pages ===//
const Error404 = Loadable(lazy(() => import('pages/404page')));
const Calender = Loadable(lazy(() => import('components/Calendar')));
const ChangePass = Loadable(lazy(() => import('components/ChangePass')));
// const CreateInvestigation = Loadable(lazy(() => import('pages/CreateInvestigation')));
const CreateInvestigation2 = Loadable(lazy(() => import('pages/CreateInvestigation/index2')));
// const EditForm = Loadable(lazy(() => import('pages/EvidenceCollectionForm/EditForm')));
const EditForm2 = Loadable(lazy(() => import('pages/seizureIIF4/EditForm')));
// const EvidenceCollection = Loadable(lazy(() => import('pages/EvidenceCollectionForm/index')));
const EvidenceCollection2 = Loadable(lazy(() => import('pages/seizureIIF4')));
const History = Loadable(lazy(() => import('pages/History')));
// const EvidenceHandling = Loadable(lazy(() => import('pages/EvidenceHandling')));
const EvidenceHandling2 = Loadable(lazy(() => import('pages/EvidenceHandling/indexNew')));
const SeizureMemo = Loadable(lazy(() => import('pages/SeizureMemo')));
const Message = Loadable(lazy(() => import('pages/Message')));
const Officer = Loadable(lazy(() => import('pages/Officers')));
const OfficerLogs = Loadable(lazy(() => import('pages/Officers/OfficerLogs')));
const Profile = Loadable(lazy(() => import('components/Profile')));
const Chatbot = Loadable(lazy(() => import('pages/AIChatBot')));
const ScheduleVisit = Loadable(lazy(() => import('pages/ScheduleVisit')));
const SearchEvidence2 = Loadable(lazy(() => import('pages/SearchEvidencesSeizure')));
// const SearchEvidence = Loadable(lazy(() => import('pages/SearchEvidences')));
const Site = Loadable(lazy(() => import('components/Sitemap')));
const TrackEvidence = Loadable(lazy(() => import('pages/TrackEvidence')));
const TransferInvestigation = Loadable(lazy(() => import('components/TranferInvestigation')));
const InvestigationHistory = Loadable(lazy(() => import('pages/History/InvestigationHistory')));
const SearchSeizureMemo = Loadable(lazy(() => import('pages/SeizureMemo/SearchSeizureMemo')));
const VerifyFiles = Loadable(lazy(() => import('pages/VerifyFiles')));
const FileCompare = Loadable(lazy(() => import('pages/FileCompare')));

// === IO pages ===//
const ManageUser = Loadable(lazy(() => import('views/IO/CreateViewOnlyUser')));
const IODashboard = Loadable(lazy(() => import('views/IO/dashboard')));
const CaseOverview = Loadable(lazy(() => import('views/IO/dashboard/OverviewPage')));
const Referral = Loadable(lazy(() => import('views/IO/Referral')));
const ReferralCases = Loadable(lazy(() => import('views/IO/Referral/ReferralCases')));
const IIFForm = Loadable(lazy(() => import('pages/IIFForm')));
const QRRelink = Loadable(lazy(() => import('pages/QRRelink')));
const QRLog = Loadable(lazy(() => import('pages/QRLog')));

// === PI pages ===//
const PIDashboard = Loadable(lazy(() => import('views/PI/dashboard')));
// const PIManageRights = Loadable(lazy(() => import('views/PI/userManagement/ManageRights')));

// === SP pages ===//
const SPDashboard = Loadable(lazy(() => import('views/SP/dashboard')));
const OfficerDetails = Loadable(lazy(() => import('views/SP/dashboard/Profile')));

// === DGP pages ===//
const DGPDashboard = Loadable(lazy(() => import('views/HigherOfficer/MasterDeshboard')));

// === Judicial pages ===//
const VerifyEvidence = Loadable(lazy(() => import('views/judicial/VerifyFile')));
const JSearchEvidence = Loadable(lazy(() => import('views/judicial/SearchEvidences')));

//=====Super User pages ===//
const CreateUser = Loadable(lazy(() => import('views/SuperUser/Index')));
const SearchUser = Loadable(lazy(() => import('views/SuperUser/SearchUser')));
// const CountValidation = Loadable(lazy(() => import('views/SuperUser/CountValidation')));

//=====FSL User pages ===//
const FSL1Dashboard = Loadable(lazy(() => import('views/FSL/FSL1/Dashboard')));
const FSL2Dashboard = Loadable(lazy(() => import('views/FSL/FSL2/Dashboard')));
const FSL3Dashboard = Loadable(lazy(() => import('views/FSL/FSL3/Dashboard')));
const FSLDepartment = Loadable(lazy(() => import('views/FSL/master/Department')));
const FSLDivision = Loadable(lazy(() => import('views/FSL/master/Division')));
const FSLCreateUser = Loadable(lazy(() => import('views/FSL/master/CreateUser')));
const FSLUserlist = Loadable(lazy(() => import('views/FSL/master/UserList')));
const FSLManagerequest = Loadable(lazy(() => import('views/FSL/ManageRequest/Index')));
const FSLReport = Loadable(lazy(() => import('views/FSL/Report')));
const FSLSearch = Loadable(lazy(() => import('views/FSL/Search')));
const FSLVerifyfile = Loadable(lazy(() => import('views/FSL/VerifyFile')));
const FSLTimeline = Loadable(lazy(() => import('views/FSL/TimeLine')));
const FSLApproveReportRequest = Loadable(lazy(() => import('views/FSL/ApproveReportRequest')));
const FSLAssignCase = Loadable(lazy(() => import('views/FSL/AssignCase')));
const FSLCreateNewCase = Loadable(lazy(() => import('views/FSL/CreateNewCase')));
const FSLUpdateCaseDetails = Loadable(lazy(() => import('views/FSL/UpdateCaseDetails')));
const FSLViewCaseDetails = Loadable(lazy(() => import('views/FSL/ViewCaseDetails')));

const FSLCreateNewCase2 = Loadable(lazy(() => import('views/FSL/FSL4/CreateNewCase')));
const FSLSearchCase = Loadable(lazy(() => import('views/FSL/FSL4/Search')));
const FSLUploadReport = Loadable(lazy(() => import('views/FSL/FSL4/UploadReport')));
const FSLSearchReport = Loadable(lazy(() => import('views/FSL/FSL4/SearchReport')));
const FSLVerifyfile2 = Loadable(lazy(() => import('views/FSL/FSL4/VerifyFile')));

// seizure officer
const NcbDashboardSeizur = Loadable(lazy(() => import('views/NCBDashboard/index')));
const NcbFormSeizur = Loadable(lazy(() => import('views/NCBSeizure/Form')));
const SeizureMemoSeizur = Loadable(lazy(() => import('views/NCBSeizure/SeizureMemo')));
const NcbEvidenceHandlingSeizur = Loadable(lazy(() => import('views/NCBSeizure/EvidenceHandling')));
const NcbTrackQRSeizur = Loadable(lazy(() => import('views/NCBSeizure/TrackEvidence')));
const SearchSeizureMemoSeizur = Loadable(lazy(() => import('views/NCBSeizure/SeizureMemo/SearchSeizureMemo')));
const AuditFormSeizur2 = Loadable(lazy(() => import('views/NCBSeizure/AuditForm/index.js')));

// malkhana offcer ncb
const NcbFormMalkhana = Loadable(lazy(() => import('views/NCBMalkhana/Form')));
const NcbMalkhanaRack = Loadable(lazy(() => import('views/NCBMalkhana/MalkhanaRack')));
const SearchSeizureMemoMalkhana = Loadable(lazy(() => import('views/NCBMalkhana/SeizureMemo/SearchSeizureMemo')));
const NcbEvidenceHandlingMalkhana = Loadable(lazy(() => import('views/NCBMalkhana/EvidenceHandling')));

// malkhana esan
const MalkhanaTrackEvidence = Loadable(lazy(() => import('views/malkhana/TrackEvidence')));
const MalkhanaEvidenceHandling = Loadable(lazy(() => import('views/malkhana/EvidenceHandling')));

//=====View Only User pages ===//
const ViewOnly = Loadable(lazy(() => import('views/viewUser')));

// ==============================|| ROUTING ||============================== //

export const ProfileRoute = { path: '/profile', element: <Profile /> };
export const AIChatBotRoute = { path: '/aichatbot', element: <Chatbot /> };
export const ErrorRoute = { path: '*', element: <Error404 /> };

export const ViewRoute = {
    path: '/viewTimeline',
    element: <ViewTimeline />,
    children: [{ path: '/viewTimeline', element: <ViewOnly /> }]
};

export const IORoute = {
    path: '/io',
    element: <MainLayout />,
    children: [
        { path: '/io', element: <IODashboard /> },
        { path: '/io/evidenceCollection', element: <EvidenceCollection2 /> },
        // { path: '/io/evidenceCollection', element: <EvidenceCollection /> },
        { path: '/io/searchevidence', element: <SearchEvidence2 /> },
        // { path: '/io/searchevidence', element: <SearchEvidence /> },
        { path: '/io/add_edit_details', element: <EditForm2 /> },
        // { path: '/io/add_edit_details', element: <EditForm /> },
        { path: '/io/referral', element: <Referral /> },
        { path: '/io/verifyFiles', element: <VerifyFiles /> },
        { path: '/io/schedulevisit', element: <ScheduleVisit /> },
        { path: '/io/investigationDetails', element: <SearchEvidence2 /> },
        // { path: '/io/investigationDetails', element: <SearchEvidence /> },
        { path: '/io/referrelCases/:id', element: <ReferralCases /> },
        { path: '/io/createViewUser', element: <ManageUser /> },
        { path: '/io/calender', element: <Calender /> },
        { path: '/io/site/:id', element: <Site /> },
        { path: '/io/changePassword', element: <ChangePass /> },
        { path: '/io/message', element: <Message /> },
        { path: '/io/aichatbot', element: <Chatbot /> },
        { path: '/io/qrRelink', element: <QRRelink /> },
        { path: '/io/qrLog', element: <QRLog /> },
        { path: '/io/EvidenceHandling', element: <EvidenceHandling2 /> },
        // { path: '/io/EvidenceHandling', element: <EvidenceHandling /> },
        { path: '/io/SeizureMemo', element: <SeizureMemo /> },
        { path: '/io/TrackEvidence', element: <TrackEvidence /> },
        { path: '/io/iifforms', element: <IIFForm /> },
        { path: '/io/SearchSeizureMemo', element: <SearchSeizureMemo /> },
        { path: '/io/investigationHistory/:id', element: <History /> },
        { path: '/io/fileCompare', element: <FileCompare /> }
    ]
};

export const PIRoute = {
    path: '/pi',
    element: <MainLayout />,
    children: [
        { path: '/pi', element: <PIDashboard /> },
        { path: '/pi/evidenceCollection', element: <EvidenceCollection2 /> },
        // { path: '/pi/evidenceCollection', element: <EvidenceCollection /> },
        { path: '/pi/createInvestigation', element: <CreateInvestigation2 /> },
        // { path: '/pi/createInvestigation', element: <CreateInvestigation /> },
        { path: '/pi/transferInvestigation', element: <TransferInvestigation /> },
        { path: '/pi/searchevidence', element: <SearchEvidence2 /> },
        // { path: '/pi/searchevidence', element: <SearchEvidence /> },
        { path: '/pi/investigationHistory/:id', element: <InvestigationHistory /> },
        { path: '/pi/schedulevisit', element: <ScheduleVisit /> },
        { path: '/pi/officers', element: <Officer /> },
        { path: '/pi/officersLogs', element: <OfficerLogs /> },
        // {path: '/pi/profile',element: <PIProfile />},
        { path: '/pi/site/:id', element: <Site /> },
        { path: '/pi/qrLog', element: <QRLog /> },
        { path: '/pi/overview/:id', element: <CaseOverview /> },
        { path: '/pi/add_edit_details', element: <EditForm2 /> },
        // { path: '/pi/add_edit_details', element: <EditForm /> },
        { path: '/pi/EvidenceHandling', element: <EvidenceHandling2 /> },
        // { path: '/pi/EvidenceHandling', element: <EvidenceHandling /> },
        { path: '/pi/message', element: <Message /> },
        { path: '/pi/aichatbot', element: <Chatbot /> },
        { path: '/pi/changePassword', element: <ChangePass /> }
    ]
};

export const FSL1Route = {
    path: '/fsl1',
    element: <MainLayout />,
    children: [
        { path: '/fsl1', element: <FSL1Dashboard /> },
        { path: '/fsl1/master', element: <FSLDepartment /> },
        { path: '/fsl1/timeline', element: <FSLTimeline /> },
        { path: '/fsl1/timeline/:id', element: <FSLTimeline /> },
        { path: '/fsl1/master/department', element: <FSLDepartment /> },
        { path: '/fsl1/master/devision', element: <FSLDivision /> },
        { path: '/fsl1/master/createUser', element: <FSLCreateUser /> },
        { path: '/fsl1/master/user', element: <FSLUserlist /> },
        { path: '/fsl1/manageRequest', element: <FSLManagerequest /> },
        { path: '/fsl1/report', element: <FSLReport /> },
        { path: '/fsl1/search', element: <FSLSearch /> },
        { path: '/fsl1/verifyFile', element: <FSLVerifyfile /> }
    ]
};

export const ForensicRoute = {
    path: '/forensic',
    element: <MainLayout />,
    children: [
        // { path: '/forensic', element: <FSL1Dashboard /> },
        { path: '/forensic', element: <FSLCreateNewCase2 /> },
        { path: '/forensic/createNewCase', element: <FSLCreateNewCase2 /> },
        { path: '/forensic/searchReport', element: <FSLSearchReport /> },
        { path: '/forensic/searchCase', element: <FSLSearchCase /> },
        { path: '/forensic/uploadReport', element: <FSLUploadReport /> },
        { path: '/forensic/verifyFile', element: <FSLVerifyfile2 /> }
    ]
};
export const FSL2Route = {
    path: '/fsl2',
    element: <MainLayout />,
    children: [
        { path: '/fsl2', element: <FSL2Dashboard /> },
        { path: '/fsl2/master', element: <FSLDepartment /> },
        { path: '/fsl2/timeline', element: <FSLTimeline /> },
        { path: '/fsl2/timeline/:id', element: <FSLTimeline /> },
        { path: '/fsl2/master/department', element: <FSLDepartment /> },
        { path: '/fsl2/master/devision', element: <FSLDivision /> },
        { path: '/fsl2/master/createUser', element: <FSLCreateUser /> },
        { path: '/fsl2/master/user', element: <FSLUserlist /> },
        { path: '/fsl2/viewCaseDetails', element: <FSLViewCaseDetails /> },
        { path: '/fsl2/assignCase', element: <FSLAssignCase /> },
        { path: '/fsl2/UpdateCaseDetails', element: <FSLUpdateCaseDetails /> },
        { path: '/fsl2/manageRequest', element: <FSLManagerequest /> },
        { path: '/fsl2/createNewCase', element: <FSLCreateNewCase /> },
        { path: '/fsl2/approveReportRequest', element: <FSLApproveReportRequest /> },
        { path: '/fsl2/report', element: <FSLReport /> },
        { path: '/fsl2/search', element: <FSLSearch /> },
        { path: '/fsl2/verifyFile', element: <FSLVerifyfile /> }
    ]
};
export const FSL3Route = {
    path: '/fsl3',
    element: <MainLayout />,
    children: [
        { path: '/fsl3', element: <FSL3Dashboard /> },
        { path: '/fsl3/master', element: <FSLDepartment /> },
        { path: '/fsl3/timeline', element: <FSLTimeline /> },
        { path: '/fsl3/timeline/:id', element: <FSLTimeline /> },
        { path: '/fsl3/master/department', element: <FSLDepartment /> },
        { path: '/fsl3/master/devision', element: <FSLDivision /> },
        { path: '/fsl3/master/createUser', element: <FSLCreateUser /> },
        { path: '/fsl3/master/user', element: <FSLUserlist /> },
        { path: '/fsl3/viewCaseDetails', element: <FSLViewCaseDetails /> },
        { path: '/fsl3/assignCase', element: <FSLAssignCase /> },
        { path: '/fsl3/UpdateCaseDetails', element: <FSLUpdateCaseDetails /> },
        { path: '/fsl3/manageRequest', element: <FSLManagerequest /> },
        { path: '/fsl3/createNewCase', element: <FSLCreateNewCase /> },
        { path: '/fsl3/approveReportRequest', element: <FSLApproveReportRequest /> },
        { path: '/fsl3/report', element: <FSLReport /> },
        { path: '/fsl3/search', element: <FSLSearch /> },
        { path: '/fsl3/verifyFile', element: <FSLVerifyfile /> }
    ]
};

export const SPRoute = {
    path: '/sp',
    element: <MainLayout />,
    children: [
        { path: '/sp', element: <SPDashboard /> },
        { path: '/sp/transferInvestigation', element: <TransferInvestigation /> },
        { path: '/sp/officer_details/:username', element: <OfficerDetails /> },
        { path: '/sp/overview/:id', element: <CaseOverview /> },
        // {path: '/sp/manageRights',element: <ManageRights />},
        { path: '/sp/officers', element: <Officer /> },
        { path: '/sp/qrLog', element: <QRLog /> },
        { path: '/sp/officersLogs', element: <OfficerLogs /> },
        { path: '/sp/addCaseDitails', element: <SearchEvidence2 /> },
        // { path: '/sp/addCaseDitails', element: <SearchEvidence /> },
        { path: '/sp/evidenceCollection', element: <EvidenceCollection2 /> },
        // { path: '/sp/evidenceCollection', element: <EvidenceCollection /> },
        { path: '/sp/createInvestigation', element: <CreateInvestigation2 /> },
        // { path: '/sp/createInvestigation', element: <CreateInvestigation /> },
        { path: '/sp/site/:id', element: <Site /> },
        { path: '/sp/investigationHistory/:id', element: <InvestigationHistory /> },
        { path: '/sp/add_edit_details', element: <EditForm2 /> },
        // { path: '/sp/add_edit_details', element: <EditForm /> },
        { path: '/sp/message', element: <Message /> },
        { path: '/sp/aichatbot', element: <Chatbot /> },
        { path: '/sp/changePassword', element: <ChangePass /> }
    ]
};

export const DIGRoute = {
    path: '/dig',
    element: <MainLayout />,
    children: [
        { path: '/dig', element: <DGPDashboard /> },
        { path: '/dig/overview/:id', element: <CaseOverview /> },
        { path: '/dig/officers', element: <Officer /> },
        { path: '/dig/addCaseDitails', element: <SearchEvidence2 /> },
        // { path: '/dig/addCaseDitails', element: <SearchEvidence /> },
        { path: '/dig/site/:id', element: <Site /> },
        { path: '/dig/investigationHistory/:id', element: <InvestigationHistory /> },
        { path: '/dig/message', element: <Message /> },
        { path: '/dig/aichatbot', element: <Chatbot /> },
        { path: '/dig/add_edit_details', element: <EditForm2 /> }
        // { path: '/dig/add_edit_details', element: <EditForm /> }
    ]
};

export const IGPRoute = {
    path: '/igp',
    element: <MainLayout />,
    children: [
        { path: '/igp', element: <DGPDashboard /> },
        { path: '/igp/overview/:id', element: <CaseOverview /> },
        { path: '/igp/officers', element: <Officer /> },
        { path: '/igp/addCaseDitails', element: <SearchEvidence2 /> },
        // { path: '/igp/addCaseDitails', element: <SearchEvidence /> },
        { path: '/igp/site/:id', element: <Site /> },
        { path: '/igp/investigationHistory/:id', element: <InvestigationHistory /> },
        { path: '/igp/message', element: <Message /> },
        { path: '/igp/aichatbot', element: <Chatbot /> },
        { path: '/igp/add_edit_details', element: <EditForm2 /> }
        // { path: '/igp/add_edit_details', element: <EditForm /> }
    ]
};

export const DGPRoute = {
    path: '/dgp',
    element: <MainLayout />,
    children: [
        { path: '/dgp', element: <DGPDashboard /> },
        { path: '/dgp/overview/:id', element: <CaseOverview /> },
        { path: '/dgp/officers', element: <Officer /> },
        { path: '/dgp/addCaseDitails', element: <SearchEvidence2 /> },
        // { path: '/dgp/addCaseDitails', element: <SearchEvidence /> },
        { path: '/dgp/site/:id', element: <Site /> },
        { path: '/dgp/investigationHistory/:id', element: <InvestigationHistory /> },
        { path: '/dgp/message', element: <Message /> },
        { path: '/dgp/aichatbot', element: <Chatbot /> },
        { path: '/dgp/add_edit_details', element: <EditForm2 /> }
        // { path: '/dgp/add_edit_details', element: <EditForm /> }
    ]
};

export const ADGPRoute = {
    path: '/adgp',
    element: <MainLayout />,
    children: [
        { path: '/adgp', element: <DGPDashboard /> },
        { path: '/adgp/overview/:id', element: <CaseOverview /> },
        { path: '/adgp/officers', element: <Officer /> },
        { path: '/adgp/addCaseDitails', element: <SearchEvidence2 /> },
        // { path: '/adgp/addCaseDitails', element: <SearchEvidence /> },
        { path: '/adgp/site/:id', element: <Site /> },
        { path: '/adgp/investigationHistory/:id', element: <InvestigationHistory /> },
        { path: '/adgp/message', element: <Message /> },
        { path: '/adgp/aichatbot', element: <Chatbot /> },
        { path: '/adgp/add_edit_details', element: <EditForm2 /> }
        // { path: '/adgp/add_edit_details', element: <EditForm /> }
    ]
};

export const ACSRoute = {
    path: '/acs',
    element: <MainLayout />,
    children: [
        { path: '/acs', element: <DGPDashboard /> },
        { path: '/acs/overview/:id', element: <CaseOverview /> },
        { path: '/acs/officers', element: <Officer /> },
        { path: '/acs/addCaseDitails', element: <SearchEvidence2 /> },
        // { path: '/acs/addCaseDitails', element: <SearchEvidence /> },
        { path: '/acs/site/:id', element: <Site /> },
        { path: '/acs/investigationHistory/:id', element: <InvestigationHistory /> },
        { path: '/acs/message', element: <Message /> },
        { path: '/acs/aichatbot', element: <Chatbot /> },
        { path: '/acs/add_edit_details', element: <EditForm2 /> }
        // { path: '/acs/add_edit_details', element: <EditForm /> }
    ]
};

export const MinisterRoute = {
    path: '/minister',
    element: <MainLayout />,
    children: [
        { path: '/minister', element: <DGPDashboard /> },
        { path: '/minister/overview/:id', element: <CaseOverview /> },
        { path: '/minister/officers', element: <Officer /> },
        { path: '/minister/addCaseDitails', element: <SearchEvidence2 /> },
        // { path: '/minister/addCaseDitails', element: <SearchEvidence /> },
        { path: '/minister/site/:id', element: <Site /> },
        { path: '/minister/investigationHistory/:id', element: <InvestigationHistory /> },
        { path: '/minister/add_edit_details', element: <EditForm2 /> }
        // { path: '/minister/add_edit_details', element: <EditForm /> }
    ]
};

export const JudicialRoute = {
    path: '/judicial',
    element: <MainLayout />,
    children: [
        { path: '/judicial', element: <SearchEvidence2 /> },
        // { path: '/judicial', element: <SearchEvidence /> },
        // { path: '/judicial', element: <JSearchEvidence /> },
        { path: '/judicial/verifyEvidence', element: <VerifyEvidence /> },
        { path: '/judicial/investigationHistory/:id', element: <InvestigationHistory /> },
        { path: '/judicial/site/:id', element: <Site /> }
    ]
};
export const NCBSeizurRoute = {
    path: '/seizureOfficer',
    element: <MainLayout />,
    children: [
        { path: '/seizureOfficer', element: <NcbDashboardSeizur /> },
        { path: '/seizureOfficer/seizureMemo', element: <SeizureMemoSeizur /> },
        { path: '/seizureOfficer/form1', element: <NcbFormSeizur /> },
        { path: '/seizureOfficer/evidenceHeandling', element: <NcbEvidenceHandlingSeizur /> },
        { path: '/seizureOfficer/viewCaseDetails', element: <SearchSeizureMemoSeizur /> },
        { path: '/seizureOfficer/trackQR', element: <NcbTrackQRSeizur /> },
        { path: '/seizureOfficer/auditForm', element: <AuditFormSeizur2 /> },
        { path: '/seizureOfficer/site/:id', element: <Site /> }
    ]
};
export const NCBMalkhanaRoute = {
    path: '/malkhanaOfficer',
    element: <MainLayout />,
    children: [
        { path: '/malkhanaOfficer', element: <NcbMalkhanaRack /> },
        { path: '/malkhanaOfficer/malakhanaRack', element: <NcbMalkhanaRack /> },
        { path: '/malkhanaOfficer/viewCaseDetails', element: <SearchSeizureMemoMalkhana /> },
        // { path: '/malkhanaOfficer/viewCaseDetails', element: <SeizureMemoMalkhana /> },
        { path: '/malkhanaOfficer/form1', element: <NcbFormMalkhana /> },
        { path: '/malkhanaOfficer/trackQR', element: <NcbTrackQRSeizur /> },
        { path: '/malkhanaOfficer/evidenceHeandling', element: <NcbEvidenceHandlingMalkhana /> },
        { path: '/malkhanaOfficer/investigationHistory/:id', element: <InvestigationHistory /> },
        { path: '/malkhanaOfficer/site/:id', element: <Site /> }
    ]
};

export const MalkhanaRoute = {
    path: '/malkhana',
    element: <MainLayout />,
    children: [
        { path: '/malkhana', element: <MalkhanaEvidenceHandling /> },
        { path: '/malkhana/changePassword', element: <ChangePass /> },
        { path: '/malkhana/message', element: <Message /> },
        { path: '/malkhana/aichatbot', element: <Chatbot /> },
        { path: '/malkhana/EvidenceHandling', element: <MalkhanaEvidenceHandling /> },
        { path: '/malkhana/TrackEvidence', element: <MalkhanaTrackEvidence /> },
        { path: '/malkhana/qrRelink', element: <QRRelink /> },
        { path: '/malkhana/qrLog', element: <QRLog /> }
    ]
};

export const SuperUserRoute = {
    path: '/superAdmin',
    element: <MainLayout />,
    children: [
        { path: '/superAdmin', element: <CreateUser /> },
        { path: '/superAdmin/searchUser', element: <SearchUser /> }
        // { path: '/superAdmin/countValidation', element: <CountValidation /> }
    ]
};
