import { useCallback } from 'react';
import { Box, Fab, Slide, useScrollTrigger } from '@mui/material';
import { KeyboardArrowUp } from '@mui/icons-material';

function ScrollToTop() {
    const trigger = useScrollTrigger({
        threshold: 100
    });

    const scrollToTop = useCallback(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <Slide direction="up" in={trigger} unmountOnExit>
            <Box
                role="presentation"
                sx={{
                    position: 'fixed',
                    bottom: 32,
                    right: 32,
                    zIndex: 9990
                }}
            >
                <Fab
                    sx={{ '&:hover': { backgroundColor: '#304775' } }}
                    onClick={scrollToTop}
                    color="primary"
                    size="small"
                    aria-label="Scroll back to top"
                >
                    <KeyboardArrowUp fontSize="medium" />
                </Fab>
            </Box>
        </Slide>
    );
}

export default ScrollToTop;
