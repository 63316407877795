import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';

// third party
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

//redux-persist
import { store } from './store';

// project imports
import App from 'App';
import * as serviceWorker from 'serviceWorker';

// style + assets
import 'assets/scss/style.scss';
import InmLogo from 'assets/VID-20231128-WA0000.mp4';
import 'assets/font/Quicksand/Quicksand-VariableFont_wght.ttf';

import config from './config';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true });
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback || <div>Something went wrong.</div>;
        }

        return this.props.children;
    }
}

const ErrorLogo = () => {
    return (
        <div
            style={{
                backgroundColor: 'white',
                width: '98vw',
                height: '98vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <video width="400" height="300" controls={false} autoPlay loop>
                <source src={InmLogo} type="video/mp4" />
                <track kind="captions" srcLang="en" label="English" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

const container = document.getElementById('ssbDigital');
const root = createRoot(container);

root.render(
    <ErrorBoundary fallback={<ErrorLogo />}>
        <Provider store={store}>
            <BrowserRouter basename={config.basename}>
                <App />
            </BrowserRouter>
        </Provider>
    </ErrorBoundary>
);

serviceWorker.unregister();
