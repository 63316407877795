import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Chip, Menu, MenuItem, Toolbar, Typography, useMediaQuery } from '@mui/material';

import { postMethodFun } from 'utils/Apirequests';
import Logo from 'ui-component/Logo';

// styles
const Main = styled('main')(({ theme }) => ({
    ...theme.typography.mainContent,
    marginLeft: '280px',
    marginTop: '20px',
    [theme.breakpoints.down('md')]: { marginLeft: '20px', marginTop: '10px', width: `calc(100% - 40px)` },
    padding: 0
}));

const Header = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const [user, setUser] = useState({});
    useEffect(() => {
        const userDetails = async () => {
            try {
                const res = await postMethodFun({ token: sessionStorage.getItem('token') }, 'getViewUserDetails');
                setUser(res?.data?.message || {});
            } catch (error) {
                console.error(error.message);
            }
        };
        userDetails();
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const logout = () => {
        navigate('/loginViewUser');
        localStorage.clear();
        sessionStorage.clear();
    };

    return (
        <>
            <Box sx={{ width: 228, display: 'flex', alignItems: 'center' }}>
                <Logo />
                <Typography variant="h4" sx={{ ml: 2, color: 'white' }}>
                    E-Sanrakshan
                </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />
            <div>
                {user.firstname ? (
                    <Chip
                        label={user.firstname[0].toUpperCase()}
                        sx={{
                            height: '45px',
                            width: '45px',
                            fontSize: 18,
                            fontWeight: 500,
                            borderRadius: '27px',
                            backgroundColor: 'lightBlue'
                        }}
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    />
                ) : null}
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button'
                    }}
                >
                    <Typography sx={{ width: 220, m: 2 }}>
                        Name : {user.firstname} {user.lastname}
                    </Typography>
                    <Typography sx={{ width: 220, m: 2 }}>Occupation : {user.occupation}</Typography>
                    <Typography sx={{ width: 220, m: 2 }}>Reason For Request : {user.reasonForRequest}</Typography>
                    <MenuItem onClick={logout}>Logout</MenuItem>
                </Menu>
            </div>
        </>
    );
};

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const drawerOpened = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{ bgcolor: '#212121', transition: drawerOpened ? theme.transitions.create('width') : 'none' }}
            >
                <Toolbar sx={{ p: 1 }}>
                    <Header />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Box component="nav" aria-label="mailbox folders">
                {!drawerOpened ? (
                    <Box
                        component="nav"
                        sx={{ bgcolor: 'black', minHeight: '100vh', width: '260px', mt: '50px', py: 1, position: 'fixed' }}
                    >
                        <Typography component="p" sx={{ fontSize: 15, bgcolor: 'white', mx: 3, my: 5, p: 1.5, borderRadius: 3 }}>
                            Investigation Timeline
                        </Typography>
                    </Box>
                ) : null}
            </Box>

            {/* main content */}
            <Main theme={theme}>
                <Outlet />
            </Main>
        </Box>
    );
};

export default MainLayout;
