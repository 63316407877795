import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, Typography } from '@mui/material';

// logo
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

// let role = sessionStorage.getItem('role');
// if (role === 'SO') {
//     role = 'sp';
// }

const LogoSection = () => <Logo width="40" />;
// const LogoSection = () => (
//     <>
//         <ButtonBase disableRipple>
//             <Logo width="40" />
//             <Link to={`/${role}`} style={{ textDecoration: 'none' }}>
//                 <Typography variant="h2" sx={{ ml: 2, color: 'white' }}>
//                     e-Sanrakshan
//                 </Typography>
//             </Link>
//         </ButtonBase>
//     </>
// );

export default LogoSection;
