import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import scheduleAppointmentReducers from './scheduleVisit';

import SearchEvidenceReducer from './searchEvidence';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    scheduleAppointment: scheduleAppointmentReducers,
    searchEvidence: SearchEvidenceReducer
});

// ==============================|| REDUX - MAIN STORE ||============================== //

export const store = configureStore({
    reducer: reducer
});
