import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material-ui
import { Collapse, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project imports
import NavItem from './NavItem3';

// assets
import { IconChevronDown } from '@tabler/icons-react';
import {
    IconGauge,
    IconSearch,
    IconUsers,
    IconFilePlus,
    IconClipboard,
    IconDeviceTabletSearch,
    IconUserCog,
    IconSquarePlus2,
    IconPlaylistAdd,
    IconHelpHexagon,
    IconReport,
    IconChecklist,
    IconFileDescription,
    IconFilePencil
} from '@tabler/icons-react';
import { IconVersions, IconFileCheck, IconFileInfo, IconUserPlus, IconUserSearch, IconPointFilled } from '@tabler/icons-react';
import { IconFileFilled, IconTimeline, IconPackageExport, IconReportSearch } from '@tabler/icons-react';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level }) => {
    const Icons = {
        IconGauge,
        IconSearch,
        IconUsers,
        IconFilePlus,
        IconClipboard,
        IconDeviceTabletSearch,
        IconUserCog,
        IconSquarePlus2,
        IconPlaylistAdd,
        IconHelpHexagon,
        IconReport,
        IconChecklist,
        IconFileDescription,
        IconFilePencil,
        IconVersions,
        IconFileCheck,
        IconFileInfo,
        IconUserPlus,
        IconUserSearch,
        IconPointFilled,
        IconFileFilled,
        IconTimeline,
        IconPackageExport,
        IconReportSearch
    };
    const customization = useSelector((state) => state.customization);

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);

    const handleClick = () => {
        setOpen(!open);
        setSelected(!selected ? menu.id : null);
    };

    const Icon = Icons[menu.icon] || menu.icon;

    return (
        <>
            <ListItemButton
                sx={{
                    borderRadius: `${customization.borderRadius}px`,
                    mb: 0.5,
                    backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                    backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                    '&.Mui-selected': {
                        backgroundColor: '#485468 !important'
                    },
                    '&:hover': {
                        backgroundColor: '#485468 !important'
                    },
                    pl: 'inherit',
                    color: 'white'
                }}
                selected={selected === menu.id}
                onClick={handleClick}
            >
                <ListItemIcon sx={{ my: 'auto', minWidth: 36, color: '#bababa' }}>
                    <Icon strokeWidth={2} size="1.4rem" style={{ color: '#FFFFFF' }} />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant={selected === menu.id ? 'h5' : 'body1'} color="inherit" style={{ color: '#FFFFFF' }}>
                            {menu.title}
                        </Typography>
                    }
                />
                <IconChevronDown
                    stroke={1.5}
                    size="1rem"
                    style={{
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        transition: '600ms',
                        transform: open ? 'rotate(180deg)' : '',
                        color: '#FFFFFF'
                    }}
                />
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {menu?.children?.map((item) => (
                    <NavItem key={item.id} item={item} level={level + 1} />
                ))}
            </Collapse>
        </>
    );
};

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number
};

export default NavCollapse;
