import { useTranslation } from 'react-i18next';
import { IconLanguageHiragana, IconMessage2, IconMessageDots } from '@tabler/icons-react';

// project imports
import NavItem from './NavItem3';
// import NavItem from './NavItem';
import NavCollapseLang from './NacCollapse';
import NavCollapse from './NacCollapse2';
import { Link } from 'react-router-dom';
import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { forwardRef } from 'react';

/* ------------------------------------------------------------ */
/*                        ⚛️ MENU LIST ⚛️                      */
/* ------------------------------------------------------------ */
const MenuList = ({ menuItem }) => {
    const { t } = useTranslation();
    let role = sessionStorage.getItem('role')?.toUpperCase() == 'SO' ? 'sp' : sessionStorage.getItem('role');
    const customization = useSelector((state) => state.customization);
    let listItemProps = {
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to="https://esamlaap.ssbd.in/" target="_blank" />)
    };
    return (
        <>
            {menuItem?.children?.map((item) =>
                item?.type == 'item' ? <NavItem key={item.id} item={item} level={1} /> : <NavCollapse key={item.id} menu={item} level={1} />
            )}
            {!(
                role?.toLowerCase() === 'judicial' ||
                role?.toUpperCase() === 'MINISTER' ||
                role?.toLowerCase() === 'superadmin' ||
                role?.toLowerCase() === 'forensic' ||
                role?.toLowerCase() === 'malkhanaofficer' ||
                role?.toLowerCase() === 'seizureofficer' ||
                !role?.toLowerCase()
            ) ? (
                <>
                    <NavItem
                        item={{
                            id: 'messages',
                            title: 'Message',
                            type: 'item',
                            url: `/${role}/message`,
                            icon: IconMessageDots,
                            breadcrumbs: false,
                            color: 'green'
                        }}
                        level={1}
                    />
                    <ListItemButton
                        {...listItemProps}
                        sx={{
                            borderRadius: `12px`,
                            mb: '2px',
                            alignItems: 'flex-start',
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: '#485468 !important' },
                            pt: 1,
                            color: 'white',
                            pl: 'inherit',
                            ml: 'inherit'
                        }}
                    >
                        <ListItemIcon sx={{ my: 'auto', minWidth: 36, color: '#bababa' }}>
                            <IconMessage2 stroke={2} size="1.4rem" style={{ color: '#FFFFFF' }} />
                        </ListItemIcon>
                        <ListItemText style={{ color: '#FFFFFF' }} primary={t('Chat bot')} />
                    </ListItemButton>
                </>
            ) : null}
            {!!role?.toLowerCase() && (
                <NavCollapseLang
                    menu={{
                        id: 'language',
                        title: t('Language'),
                        icon: IconLanguageHiragana,
                        children: [
                            { id: 'en', title: 'English', type: 'item' },
                            { id: 'hi', title: 'हिन्दी', type: 'item' },
                            { id: 'as', title: 'অসমীয়া', type: 'item' },
                            { id: 'bn', title: 'বাংলা', type: 'item' }
                        ]
                    }}
                    level={1}
                />
            )}
        </>
    );
};

export default MenuList;
