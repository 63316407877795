import { create } from 'zustand';
import Toast from './toast';

export const useToast = create((set) => ({
    toasts: [],
    open: ({ message, variant = 'basic', color = 'info', time = 4000 }) => {
        const id = Date.now();
        set((state) => ({ toasts: [...state.toasts, { id, message, variant, color, time }] }));

        setTimeout(() => {
            set((state) => ({ toasts: state.toasts.filter((toast) => toast.id !== id) }));
        }, time);
    },
    toast: ({ message, variant = 'basic', color = 'success', time = 4000 }) => {
        const id = Date.now();
        set((state) => ({ toasts: [...state.toasts, { id, message, variant, color, time }] }));

        setTimeout(() => {
            set((state) => ({ toasts: state.toasts.filter((toast) => toast.id !== id) }));
        }, time);
    },
    close: (id) => set((state) => ({ toasts: state.toasts.filter((toast) => toast.id !== id) }))
}));

const container = { position: 'fixed', bottom: '10px', right: '5px', width: '300px', zIndex: 9998 };

const ToastContainer = () => {
    const { toasts, close } = useToast();
    return (
        <div style={container}>
            {toasts?.map((toast) => (
                <Toast {...toast} close={close} key={toast.id} heading="" />
            ))}
        </div>
    );
};

export default ToastContainer;
