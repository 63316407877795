import React, { useEffect, useState } from 'react';

import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { IconBrandTelegram, IconDownload } from '@tabler/icons-react';

import MediaURL, { postMethodFun } from 'utils/Apirequests';
import { DateConvertor } from 'utils/DateConvertor';

const ViewMessages = ({ message: msg }) => {
    const theme = useTheme();
    const username = sessionStorage.getItem('username');
    const token = sessionStorage.getItem('token');
    const [message, setMessage] = useState({});
    // useEffect(() => {
    //     // getMessageDetail();
    // }, [msg]);

    const getMessageDetail = async () => {
        let response = await postMethodFun({ msgId: msg._id, token }, 'getThreadOfMessages');
        // let { mainMSG, replise } = response.data;
        let files = msg.files ? msg.files : [];
        if (msg.files && typeof msg.files == 'string') {
            files = JSON.parse(msg.files);
        }
        console.log(msg);
        setMessage({ ...msg, files });
        //   mainMSG ? setMessage(mainMSG) : null;
    };
    const [reply, setReply] = useState({ message: '', files: '', to: '', parentMsgId: '' });
    const replyForm = async () => {
        const { message, files, to, parentMsgId } = reply;

        if (message) {
            const sendData = {
                message,
                files,
                from: username,
                to,
                type: 'PERSONAL',
                option: '',
                spOffice: '',
                policeStation: '',
                priority: '',
                isReply: true,
                parentMsgId,
                token
            };
            try {
                await postMethodFun(sendData, 'writeMessage');
            } catch (error) {
                console.log('error');
            }
        }
    };
    return (
        <div>
            <Dialog
                open={message.from ? true : false}
                onClose={() => setMessage({})}
                sx={{ minWidth: 400 }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" variant="h3">
                    Message
                </DialogTitle>
                <DialogContent sx={{ p: 1 }}>
                    <Box sx={{ minWidth: 400 }}>
                        <ListItem alignItems="center" sx={{ p: 1, m: 0 }}>
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        color: theme.palette.warning.dark,
                                        backgroundColor: theme.palette.warning.light,
                                        border: 'none',
                                        borderColor: theme.palette.warning.main
                                    }}
                                    alt={message.from && message.from}
                                >
                                    {message.from && message.from[0]}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={<Typography variant="subtitle1">{message.from && message.from}</Typography>} />
                        </ListItem>
                        <Typography variant="caption" marginTop="-20px" marginRight={2} textAlign="right" display="block">
                            {DateConvertor(message?.time, 'll, HH:mm')}
                        </Typography>
                        <Grid container direction="column" className="list-container" sx={{ ml: '20px' }}>
                            <Grid item xs={12} sx={{ pb: 1 }}>
                                <Typography variant="subtitle1" sx={{ mx: 2 }}>
                                    {message.msg && message.msg}
                                    {message.priority &&
                                    (message.priority === 'veryHigh' ||
                                        message.priority === 'VeryHigh' ||
                                        message.priority === 'Very High') ? (
                                        <Typography
                                            sx={{
                                                display: 'inline-block',
                                                fontSize: 9,
                                                fontWeight: 600,
                                                p: '3px 9px',
                                                m: '2px 0 0 7px',
                                                backgroundColor: '#FF2d2d',
                                                color: 'white',
                                                borderRadius: '10px'
                                            }}
                                        >
                                            Very High
                                        </Typography>
                                    ) : null}
                                    {message.priority && (message.priority === 'high' || message.priority === 'High') ? (
                                        <Typography
                                            sx={{
                                                display: 'inline-block',
                                                fontSize: 9,
                                                fontWeight: 600,
                                                p: '1px 9px',
                                                backgroundColor: '#ffd633',
                                                m: '2px 0 0 7px',
                                                color: '#353535',
                                                borderRadius: '10px'
                                            }}
                                        >
                                            High
                                        </Typography>
                                    ) : null}
                                </Typography>
                            </Grid>
                            {message.files && message.files.length ? (
                                <Box sx={{ bgcolor: '#ffffff99', px: 2, borderRadius: 1, mr: 2, py: 1 }}>
                                    <Typography
                                        variant="h5"
                                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                    >
                                        Files
                                    </Typography>
                                    <Divider sx={{ pt: 1 }} />
                                    <Box sx={{ px: 1 }}>
                                        <table style={{ width: '100%', marginRight: '6px' }}>
                                            <tbody style={{ fontSize: 11 }}>
                                                {message.files.map(({ filename }, i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            {i + 1}. {filename}
                                                        </td>
                                                        <td>
                                                            <Button content="a" href={`${MediaURL.get}/${filename}`}>
                                                                <IconDownload />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Box>
                                </Box>
                            ) : null}
                            <Box sx={{ m: 2, position: 'relative' }}>
                                <TextField
                                    variant="filled"
                                    label="Reply"
                                    sx={{ width: '96%' }}
                                    size="small"
                                    onChange={(e) => setReply({ ...reply, message: e.target.value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <IconBrandTelegram onClick={replyForm} color="#558CFF" />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ViewMessages;
