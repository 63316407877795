import moment from 'moment';

export const DateConvertor = (date, format) => {
    if (!date) {
        return '';
    }
    if (moment(date, true).isValid()) {
        return moment(date, true).format(format);
    }
    if (moment(+date, true).isValid()) {
        return moment(+date, true).format(format);
    }

    return '';
};
