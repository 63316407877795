import { useEffect } from 'react';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// tabler-icons
import { IconGauge } from '@tabler/icons-react';

// threrd party
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

// project imports
import Header from './Header';
import NavItem from './navItem.json';
import Sidebar from './Sidebar';
import { SET_MENU } from 'store/actions';
import { drawerWidth } from 'store/constant';
import { postMethodFun } from 'utils/Apirequests';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: { marginLeft: -(drawerWidth - 20), width: `calc(100% - ${drawerWidth}px)` },
        [theme.breakpoints.down('md')]: { marginLeft: '20px', width: `calc(100% - ${drawerWidth}px)` },
        [theme.breakpoints.down('sm')]: { marginLeft: '10px', width: `calc(100% - ${drawerWidth}px)`, marginRight: '10px' }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 20,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: { marginLeft: '20px' },
        [theme.breakpoints.down('sm')]: { marginLeft: '10px' }
    }),
    padding: 0,
    marginTop: '70px'
}));

const timeout = 1000 * 60 * 15;

/* ------------------------------------------------------------ */
/*                      ⚛️ MAIN LAYOUT ⚛️                      */
/* ------------------------------------------------------------ */
const MainLayout = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let role = sessionStorage.getItem('role')?.toLowerCase();
    role = role === 'so' ? 'sp' : role;

    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const leftDrawerOpened = useSelector((state) => state.customization.opened);

    // Handle left drawer
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
    }, [dispatch, matchDownMd]);

    let children = NavItem[role] || [
        { id: 'dashboard', title: 'Dashboard', type: 'item', url: '/dashboard', icon: IconGauge, breadcrumbs: false }
    ];

    let menuItem = { id: 'pages', title: 'Pages', caption: 'Pages Caption', type: 'group', children };

    const onIdle = async () => {
        const token = sessionStorage.getItem('token');
        sessionStorage.clear();
        navigate('/');
        try {
            await postMethodFun({ endTime: Date.now(), token }, 'logout');
        } catch (error) {
            console.error(error.message);
        }
    };

    useIdleTimer({ onIdle, timeout, throttle: 500 });

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                elevation={0}
                sx={{ bgcolor: '#101626', transition: leftDrawerOpened ? theme.transitions.create('width') : 'none' }}
            >
                <Toolbar sx={{ p: 1 }}>
                    {!!role ? <Header handleLeftDrawerToggle={handleLeftDrawerToggle} /> : <h3>eSanrakshan</h3>}
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar menuItem={menuItem} drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}>
                <Outlet />
            </Main>
        </Box>
    );
};

export default MainLayout;
