import PropTypes from 'prop-types';

// material-ui
import { Box, IconButton } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons-react';

/* ------------------------------------------------------------ */
/*                   ⚛️ MAIN NAVBAR / HEADER ⚛️                */
/* ------------------------------------------------------------ */
const Header = ({ handleLeftDrawerToggle }) => {
    return (
        <>
            <Box sx={{ width: 228, display: 'flex' }}>
                <IconButton onClick={handleLeftDrawerToggle} sx={{ mr: 1 }} aria-label="Menu">
                    <IconMenu2 color="white" stroke={3} size="1.6rem" />
                </IconButton>
                <Box component="span" sx={{ flexGrow: 1, paddingTop: '6px' }}>
                    <LogoSection />
                </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <NotificationSection />
            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
