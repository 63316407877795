import { useTranslation } from 'react-i18next';
import { IconLanguageHiragana, IconMessage2, IconMessageDots } from '@tabler/icons-react';

// project imports
import NavItem from './NavItem3';
// import NavItem from './NavItem';
import NavCollapseLang from './NacCollapse';
import NavCollapse from './NacCollapse2';

/* ------------------------------------------------------------ */
/*                        ⚛️ MENU LIST ⚛️                      */
/* ------------------------------------------------------------ */
const MenuList = ({ menuItem }) => {
    const { t } = useTranslation();
    let role = sessionStorage.getItem('role')?.toUpperCase() == 'SO' ? 'sp' : sessionStorage.getItem('role');
    return (
        <>
            {menuItem?.children?.map((item) =>
                item?.type == 'item' ? <NavItem key={item.id} item={item} level={1} /> : <NavCollapse key={item.id} menu={item} level={1} />
            )}
            {!(
                role?.toLowerCase() === 'judicial' ||
                role?.toUpperCase() === 'MINISTER' ||
                role?.toLowerCase() === 'superadmin' ||
                role?.toLowerCase() === 'forensic' ||
                role?.toLowerCase() === 'malkhanaofficer' ||
                role?.toLowerCase() === 'seizureofficer' ||
                !role?.toLowerCase()
            ) ? (
                <>
                    <NavItem
                        item={{
                            id: 'messages',
                            title: 'Message',
                            type: 'item',
                            url: `/${role}/message`,
                            icon: IconMessageDots,
                            breadcrumbs: false,
                            color: 'green'
                        }}
                        level={1}
                    />
                    {/* <NavItem
                        item={{
                            id: 'messages2',
                            title: 'Chatbot',
                            type: 'item',
                            url: `/${role}/aichatbot`,
                            icon: IconMessage2,
                            breadcrumbs: false,
                            color: 'green'
                        }}
                        level={1}
                    /> */}
                </>
            ) : null}
            {!!role?.toLowerCase() && (
                <NavCollapseLang
                    menu={{
                        id: 'language',
                        title: t('Language'),
                        icon: IconLanguageHiragana,
                        children: [
                            { id: 'en', title: 'English', type: 'item' },
                            { id: 'hi', title: 'हिन्दी', type: 'item' },
                            { id: 'as', title: 'অসমীয়া', type: 'item' },
                            { id: 'bn', title: 'বাংলা', type: 'item' }
                        ]
                    }}
                    level={1}
                />
            )}
        </>
    );
};

export default MenuList;
