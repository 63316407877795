import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

export const appointmentSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        scheduleAppointment(state, action) {
            let length = state.length;
            if (action.payload.type === 'add') {
                state.push(action.payload.data);
            } else if (action.payload.type === 'delete') {
                state.splice(0);
            }
        }
    }
});

// Action creators are generated for each case reducer function
export const { scheduleAppointment } = appointmentSlice.actions;

export default appointmentSlice.reducer;
