import { useState } from 'react';
import React from 'react';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Button,
    ButtonBase,
    Card,
    CardContent,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import { Box } from '@mui/system';

// assets
import { IconArrowBackUp, IconBrandTelegram, IconBuildingStore, IconChevronDown, IconMailbox, IconPhoto } from '@tabler/icons-react';
import moment from 'moment';
import { motion } from 'framer-motion';
import { IconDownload } from '@tabler/icons-react';
import ViewMessages from './ViewMessages';
import MediaURL, { postMethodFun } from 'utils/Apirequests';
import { DateConvertor } from 'utils/DateConvertor';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ type, data }) => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const username = sessionStorage.getItem('username');
    const token = sessionStorage.getItem('token');

    const [toggleShow, setToggleShow] = useState(false);

    const [msgId, setMsgId] = useState('');
    const [message, setMessage] = useState({});
    const getMessageDetail = async () => {
        let response = await postMethodFun({ userId: username, token }, 'getMessages');
    };
    const [reply, setReply] = useState({ message: '', files: '', to: '', parentMsgId: '' });
    const replyForm = async () => {
        const { message, files, to, parentMsgId } = reply;
        if (message) {
            const sendData = {
                message,
                files,
                from: username,
                to,
                type: 'PERSONAL',
                option: '',
                spOffice: '',
                policeStation: '',
                priority: '',
                isReply: false,
                parentMsgId,
                token
            };
            try {
                await postMethodFun(sendData, 'writeMessage');
            } catch (error) {
                console.error(error.message);
            }
        }
    };
    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                p: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: { minWidth: matchesXs ? 350 : 500 },
                minWidth: matchesXs ? 350 : 400,
                '& .MuiListItemSecondaryAction-root': { top: 22 },
                '& .MuiDivider-root': { my: 0 },
                '& .list-container': { pl: 7 }
            }}
        >
            <ViewMessages message={message} />
            {/* <Dialog
                open={message.from ? true : false}
                onClose={() => setMessage({})}
                sx={{ minWidth: 400 }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" variant="h3">
                    Message
                </DialogTitle>
                <DialogContent sx={{ p: 1 }}>
                    <Box sx={{ minWidth: 400 }}>
                        <ListItem alignItems="center" sx={{ p: 1, m: 0 }}>
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        color: theme.palette.warning.dark,
                                        backgroundColor: theme.palette.warning.light,
                                        border: 'none',
                                        borderColor: theme.palette.warning.main
                                    }}
                                    alt={message.from && message.from}
                                >
                                    {message.from && message.from[0]}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={<Typography variant="subtitle1">{message.from && message.from}</Typography>} />
                        </ListItem>
                        <Typography variant="caption" marginTop="-20px" marginRight={2} textAlign="right" display="block">
                            {message.time ? moment(+message.time).format('ll, HH:mm') : null}
                        </Typography>
                        <Grid container direction="column" className="list-container" sx={{ ml: '20px' }}>
                            <Grid item xs={12} sx={{ pb: 1 }}>
                                <Typography variant="subtitle1" sx={{ mx: 2 }}>
                                    {message.msg && message.msg}
                                    {message.priority &&
                                    (message.priority === 'veryHigh' ||
                                        message.priority === 'VeryHigh' ||
                                        message.priority === 'Very High') ? (
                                        <Typography
                                            sx={{
                                                display: 'inline-block',
                                                fontSize: 9,
                                                fontWeight: 600,
                                                p: '3px 9px',
                                                m: '2px 0 0 7px',
                                                backgroundColor: '#FF2d2d',
                                                color: 'white',
                                                borderRadius: '10px'
                                            }}
                                        >
                                            Very High
                                        </Typography>
                                    ) : null}
                                    {message.priority && (message.priority === 'high' || message.priority === 'High') ? (
                                        <Typography
                                            sx={{
                                                display: 'inline-block',
                                                fontSize: 9,
                                                fontWeight: 600,
                                                p: '1px 9px',
                                                backgroundColor: '#ffd633',
                                                m: '2px 0 0 7px',
                                                color: '#353535',
                                                borderRadius: '10px'
                                            }}
                                        >
                                            High
                                        </Typography>
                                    ) : null}
                                </Typography>
                            </Grid>
                            {message.files && message.files.length ? (
                                <Box sx={{ bgcolor: '#ffffff99', px: 2, borderRadius: 1, mr: 2, py: 1 }}>
                                    <Typography
                                        variant="h5"
                                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                    >
                                        Files
                                    </Typography>
                                    <Divider sx={{ pt: 1 }} />
                                    <Box sx={{ px: 1 }}>
                                        <table style={{ width: '100%', marginRight: '6px' }}>
                                            <tbody style={{ fontSize: 11 }}>
                                                {message.files.map(({ filename }, i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            {i + 1}. {filename}
                                                        </td>
                                                        <td>
                                                            <Button content="a" href={`${MediaURL.get}/${filename}`}>
                                                                <IconDownload />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Box>
                                </Box>
                            ) : null}
                            <Box sx={{ m: 2, position: 'relative' }}>
                                <TextField
                                    variant="filled"
                                    label="Reply"
                                    sx={{ width: '96%' }}
                                    size="small"
                                    onChange={(e) => setReply({ ...reply, message: e.target.value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <IconBrandTelegram onClick={replyForm} color="#558CFF" />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog> */}
            {data.map((message, i) => {
                return (
                    <React.Fragment key={i}>
                        <ListItemWrapper
                            onClick={() => {
                                !message.investigationID ? setMessage(message) : null;
                            }}
                        >
                            <ListItem alignItems="center">
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            color: theme.palette.warning.dark,
                                            backgroundColor: theme.palette.warning.light,
                                            border: 'none',
                                            borderColor: theme.palette.warning.main
                                        }}
                                        alt={message.from}
                                    >
                                        {message.from[0]}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={<Typography variant="subtitle1">{message.from}</Typography>} />
                                <ListItemSecondaryAction>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item xs={12}>
                                            <Typography variant="caption" display="block" gutterBottom>
                                                {DateConvertor(message?.time, 'MMM Do YYYY, HH:mm')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Grid container direction="column" className="list-container">
                                {message.investigationID ? (
                                    <>
                                        <Grid item xs={12} sx={{ pb: 1 }}>
                                            <Typography variant="body1">
                                                {/* {message.message} */}
                                                Investigation Transfer
                                                {message.priority === 'veryHigh' ? (
                                                    <Typography
                                                        sx={{
                                                            display: 'inline-block',
                                                            fontSize: 9,
                                                            fontWeight: 600,
                                                            p: '3px 9px',
                                                            m: '2px 0 0 7px',
                                                            backgroundColor: '#FF2d2d',
                                                            color: 'white',
                                                            borderRadius: '10px'
                                                        }}
                                                    >
                                                        Very High
                                                    </Typography>
                                                ) : null}
                                                {message.priority === 'high' ? (
                                                    <Typography
                                                        sx={{
                                                            display: 'inline-block',
                                                            fontSize: 9,
                                                            fontWeight: 600,
                                                            p: '1px 9px',
                                                            backgroundColor: '#ffd633',
                                                            m: '2px 0 0 7px',
                                                            color: '#353535',
                                                            borderRadius: '10px'
                                                        }}
                                                    >
                                                        High
                                                    </Typography>
                                                ) : null}
                                            </Typography>
                                        </Grid>
                                        <Box sx={{ bgcolor: '#ffffff99', px: 1.5, borderRadius: 1, mr: 2, py: 0.5 }}>
                                            <Typography variant="subtitle1">Investigation</Typography>
                                            <table style={{ width: '100%', marginRight: '6px' }}>
                                                <tbody style={{ fontSize: 11 }}>
                                                    <tr>
                                                        <td>{message.investigationID}</td>
                                                        <td>
                                                            <Button sx={{ fontSize: 11 }} size="small">
                                                                View
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Box>
                                        <Grid item xs={12}>
                                            <Button sx={{ color: '#0091ff' }} size="small">
                                                Mark as Read
                                            </Button>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={12} sx={{ pb: 1 }}>
                                            <Typography variant="body1">
                                                {message.msg}
                                                {message.priority === 'veryHigh' ? (
                                                    <Typography
                                                        sx={{
                                                            display: 'inline-block',
                                                            fontSize: 9,
                                                            fontWeight: 600,
                                                            p: '3px 9px',
                                                            m: '2px 0 0 7px',
                                                            backgroundColor: '#FF2d2d',
                                                            color: 'white',
                                                            borderRadius: '10px'
                                                        }}
                                                    >
                                                        Very High
                                                    </Typography>
                                                ) : null}
                                                {message.priority === 'high' ? (
                                                    <Typography
                                                        sx={{
                                                            display: 'inline-block',
                                                            fontSize: 9,
                                                            fontWeight: 600,
                                                            p: '1px 9px',
                                                            backgroundColor: '#ffd633',
                                                            m: '2px 0 0 7px',
                                                            color: '#353535',
                                                            borderRadius: '10px'
                                                        }}
                                                    >
                                                        High
                                                    </Typography>
                                                ) : null}
                                            </Typography>
                                        </Grid>
                                        {/* <Box sx={{ bgcolor: '#ffffff99', px: 2, borderRadius: 1, mr: 2, py: 1 }}>
                                                <Typography
                                                    variant="h5"
                                                    onClick={() => setToggleShow(!toggleShow)}
                                                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                                >
                                                    Files
                                                </Typography>
                                                <Divider sx={{ display: !toggleShow ? 'none' : 'block', pt: 1 }} />
                                                <Box sx={{ display: !toggleShow ? 'none' : 'block', px: 1 }}>
                                                    {files.map(({ filename }, i) => (
                                                        <Typography variant="h6">
                                                            {i + 1}. {filename}
                                                            <Button sx={{ fontSize: 12 }} content="a" href={`${MediaURL.get}/${filename}`}>
                                                                Download
                                                            </Button>
                                                        </Typography>
                                                    ))}
                                                </Box>
                                            </Box> */}
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item>
                                                    <Button sx={{ color: '#0091ff', ml: -2 }} size="small">
                                                        Reply
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button sx={{ color: '#0091ff' }} size="small">
                                                        Mark as Read
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </ListItemWrapper>
                        <Divider />
                    </React.Fragment>
                );
            })}
            {/* <ListItemWrapper>
                <ListItem alignItems="center">
                    <ListItemAvatar>
                        <Avatar
                            sx={{
                                color: theme.palette.warning.dark,
                                backgroundColor: theme.palette.warning.light,
                                border: 'none',
                                borderColor: theme.palette.warning.main
                            }}
                            alt="Udalguri_IO_1"
                        >
                            U
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="subtitle1">Udalguri_IO_1</Typography>} />
                    <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={12}>
                                <Typography variant="caption" display="block" gutterBottom>
                                    {moment(+1680608825995).format('MMM Do YYYY, h:mm a')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className="list-container">
                    <Grid item xs={12} sx={{ pb: 1 }}>
                        <Typography variant="body1">
                            It is a long established fact that a reader will be distracted
                            <Typography
                                sx={{
                                    display: 'inline-block',
                                    fontSize: 9,
                                    fontWeight: 600,
                                    p: '3px 9px',
                                    m: '2px 0 0 7px',
                                    backgroundColor: '#FF2d2d',
                                    color: 'white',
                                    borderRadius: '10px'
                                }}
                            >
                                Very High
                            </Typography>
                            <Typography
                                sx={{
                                    display: 'inline-block',
                                    fontSize: 9,
                                    fontWeight: 600,
                                    p: '1px 9px',
                                    backgroundColor: '#ffd633',
                                    m: '2px 0 0 7px',
                                    color: '#353535',
                                    borderRadius: '10px'
                                }}
                            >
                                High
                            </Typography>
                        </Typography>
                    </Grid>
                    <Box sx={{ bgcolor: '#ffffff99', px: 2, borderRadius: 1, mr: 2, py: 1 }}>
                        <Typography
                            variant="h5"
                            onClick={() => setToggleShow(!toggleShow)}
                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                        >
                            Files
                        </Typography>
                        <Divider sx={{ display: !toggleShow ? 'none' : 'block', pt: 1 }} />
                        <Box sx={{ display: !toggleShow ? 'none' : 'block', px: 1 }}>
                            <Typography variant="h6">
                                1. demo.jpg <Button sx={{ fontSize: 12 }}>Download</Button>
                            </Typography>
                            <Typography variant="h6">
                                2. demo2.jpg <Button sx={{ fontSize: 12 }}>Download</Button>
                            </Typography>
                            <Typography variant="h6">
                                3. demo3.jpg <Button sx={{ fontSize: 12 }}>Download</Button>
                            </Typography>
                        </Box>
                    </Box>
                    <Stack direction="column">
                        <Typography variant="subtitle1">Investigation</Typography>
                        <table style={{ width: '100%', marginRight: '6px' }}>
                            <tbody style={{ fontSize: 11 }}>
                                <tr>
                                    <td>1680608825995</td> <td>Accident at Kamrup</td>
                                    <td>
                                        <Button sx={{ fontSize: 11 }} size="small">
                                            View
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Stack>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Button sx={{ color: '#0091ff', ml: -2 }} size="small">
                                    Reply
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button sx={{ color: '#0091ff' }} size="small">
                                    Mark as Read
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <TextField variant="filled" label="Reply" sx={{ mr: 2 }} size="small" />
                </Grid>
            </ListItemWrapper>
            <Divider /> */}
        </List>
    );
};

export default NotificationList;
