import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { IconBasic, IconError, IconInfo, IconPrimary, IconSuccess, IconWarnig, IconX } from './icons/myI';

const BGcolors = {
    primary: '#0642ff',
    success: '#0cd950',
    error: '#ff483b',
    info: '#60aaff',
    warning: '#ffd439',
    basic: '#828992',
    secondary: '#8348b0'
};

const textColors = { primary: '#FFFFFF', success: '#FFFFFF', error: '#FFFFFF', info: '#FFFFFF', warning: '#353535', basic: '#FFFFFF' };

const dynamicbtnStyle = ({ bgColor, textColor, variant }) => {
    let bgc = bgColor;
    bgc = variant === 'outlined' ? bgc + '66' : bgc;
    bgc = variant === 'light' || variant === 'shadow' ? bgc + '66' : bgc;
    bgc = variant === 'text' ? '#FFFFFF' : bgc;

    let text = '#FFFFFF';
    text = variant === 'basic' ? textColor : text;
    return css`
        background-color: ${bgc};
        color: ${text};
        &:hover {
            background-color: ${text + '33'};
        }
    `;
};

const CloseButton = styled.button`
    ${dynamicbtnStyle};
    border-radius: 3em;
    border: none;
    cursor: pointer;
    padding: 5px;
    height: 34px;
    background-color: transparent;
    transition: transform 0.3s linear;
    &:hover {
        transform: rotate(90deg);
    }
`;

const ToastWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1em 0.6em;
    box-sizing: border-box;
`;
const MessageWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0.1em 0.2em;
    gap: 0.5em;
    box-sizing: border-box;
`;

const ToastMessage = styled.p`
    margin: 15px 0;
    font-size: 1.1em;
    font-weight: 500;
`;

const dynamicStyle = ({ bgColor, textColor, variant, time }) => {
    let bgc = bgColor;
    bgc = variant === 'outlined' ? bgc + '66' : bgc;
    bgc = variant === 'light' || variant === 'shadow' ? bgc + '66' : bgc;
    bgc = variant === 'text' ? '#FFFFFF' : bgc;

    let text = bgColor;
    text = variant === 'basic' ? textColor : text;
    return css`
        background-color: ${bgc};
        color: ${text};
        position: relative;
        &::after {
            content: '';
            width: 0%;
            height: 4px;
            background-color: ${text};
            position: absolute;
            bottom: 0;
            left: 0;
            animation: toastProgress ${+time / 1000}.2s linear;
        }

        @keyframes toastProgress {
            from {
                width: 100%;
            }
            to {
                width: 0%;
            }
        }
    `;
};

const ToastContainer = styled.div`
    ${dynamicStyle};
    display: flex;
    flex-direction: column;
    border-radius: 0.8em;
    border: ${({ variant, bgColor }) => (variant === 'outlined' ? `3px solid ${bgColor}` : 'none')};
    margin: 0.4em 0.2em;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    animation-name: openToast;
    animation-duration: 0.35s;
    overflow: hidden;

    @keyframes openToast {
        0% {
            transform: translateX(100%);
        }
        60% {
            transform: translateX(-15%);
        }
        80% {
            transform: translateX(5%);
        }
        100% {
            transform: translateX(0%);
        }
    }
`;

const Toast = ({ id, message, heading = '', variant = 'basic', color = 'info', close, time }) => {
    const bgColor = BGcolors[color] || BGcolors.basic;
    const textColor = textColors[color] || textColors.basic;
    const IconComponent =
        {
            success: IconSuccess,
            basic: IconBasic,
            error: IconError,
            info: IconInfo,
            primary: IconPrimary,
            warning: IconWarnig
        }[color] || IconBasic;

    return (
        <ToastContainer bgColor={bgColor} textColor={textColor} variant={variant} time={time}>
            <ToastWrapper>
                <MessageWrapper>
                    <IconComponent />
                    <ToastMessage>{message}</ToastMessage>
                </MessageWrapper>
                <CloseButton bgColor={bgColor} textColor={textColor} variant={variant} onClick={() => close(id)}>
                    <IconX />
                </CloseButton>
            </ToastWrapper>
        </ToastContainer>
    );
};

export default Toast;
