import { useState, useRef, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { ButtonBase, ClickAwayListener, Divider, Grid, Paper, Popper, Tooltip, Typography, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';
import { IconBell } from '@tabler/icons-react';
import { postMethodFun } from 'utils/Apirequests';

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const username = sessionStorage.getItem('username');
    const token = sessionStorage.getItem('token');

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('New');
    const [messages, setMessages] = useState({});
    const [displayMessages, setDisplayMessages] = useState([]);
    const [sent, setSent] = useState([]);

    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleChange = ({ target }) => {
        let { value } = target;
        let { readMessages, unreadMessages } = messages;
        setValue(value);
        value === 'New'
            ? readMessages
                ? setDisplayMessages([...readMessages])
                : setDisplayMessages([])
            : unreadMessages
            ? setDisplayMessages([...unreadMessages])
            : setDisplayMessages([]);
        // if (event?.target.value) setValue(event?.target.value);
    };

    useEffect(() => {
        getMessages();
    }, []);
    const getMessages = async () => {
        try {
            const response = await postMethodFun({ userId: username, token }, 'getMessages');
            let { inbox, sent } = response.data;
            inbox ? setMessages({ ...inbox }) : setMessages({});
            inbox.readMessages ? setDisplayMessages([...inbox.readMessages]) : setDisplayMessages([]);
            sent ? setSent([...sent]) : setSent([]);
        } catch (error) {
            console.error(error.message);
        }
    };

    return (
        <>
            <Tooltip title="Notification">
                <ButtonBase
                    sx={{ borderRadius: '50px', bgcolor: '#313131', p: '6px', boxShadow: '0px 0px 30px 1px #484848', color: '#f2f2f2' }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <IconBell />
                </ButtonBase>
            </Tooltip>

            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{ modifiers: [{ name: 'offset', options: { offset: [matchesXs ? 5 : 0, 20] } }] }}
            >
                {({ TransitionProps }) => (
                    <Transitions position="top" in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography sx={{ mx: 2, mt: 2 }} variant="h4">
                                                Notification
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PerfectScrollbar
                                                style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                                            >
                                                <Divider sx={{ my: 0 }} />
                                                {displayMessages && displayMessages.length ? (
                                                    <NotificationList type={value} data={displayMessages} />
                                                ) : (
                                                    <>
                                                        <Typography
                                                            sx={{
                                                                width: '100%',
                                                                maxWidth: 330,
                                                                [theme.breakpoints.down('md')]: { minWidth: matchesXs ? 350 : 500 },
                                                                minWidth: matchesXs ? 350 : 400
                                                            }}
                                                            padding={2}
                                                        >
                                                            No Notification available
                                                        </Typography>
                                                    </>
                                                )}
                                            </PerfectScrollbar>
                                        </Grid>
                                    </Grid>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
