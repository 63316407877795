// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-size: 12px;
    background-color: #F4F6F8 !important;
}

@page {
    size: auto;
    margin: 15mm;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
}

::-webkit-scrollbar-track-piece {
    background-color: #f1f1f1;
    box-shadow: inset 1px 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

body > iframe {
    display: none;
}
`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,oCAAoC;AACxC;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA,UAAU;AACV;IACI,UAAU;IACV,WAAW;AACf;;AAEA,UAAU;AACV;IACI,mBAAmB;AACvB;;AAEA,WAAW;AACX;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,8CAA8C;AAClD;;AAEA,oBAAoB;AACpB;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB","sourcesContent":["body {\n    font-size: 12px;\n    background-color: #F4F6F8 !important;\n}\n\n@page {\n    size: auto;\n    margin: 15mm;\n}\n\n/* width */\n::-webkit-scrollbar {\n    width: 8px;\n    height: 8px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n    background: #f1f1f1;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n    background: #999;\n    border-radius: 10px;\n}\n\n::-webkit-scrollbar-track-piece {\n    background-color: #f1f1f1;\n    box-shadow: inset 1px 0 6px rgba(0, 0, 0, 0.3);\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n    background: #555;\n}\n\nbody > iframe {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
